/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  // overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  color: #555555;
}
.wrap {
  padding: 30px 0;
}
.container-fluid.but-fixed {
  max-width: 1356px;
}
.font-weight-strong {
  font-weight: 600;
}
.footer {
  padding-top: 20px;
  padding-bottom: 15px;
  width: 100%;
  background-color: #f5f5f5;
  color: #707070;
  border-top: 1px solid #e4e4e4;
  font-size: 0.9rem;
  p {
    margin: 0 0 5px 0;
  }
  p.trustmark {
    display: none;
    margin: 0;
  }
  a {
    color: #707070;
  }
  .social a {
    border: 0;
  }
  img {
    display: inline-block;
  }
  #trustwaveSealImage {
    margin: 0 10px 30px 0;
  }
}
#banner-wrap {
  background: #f2f9ff;
}
#banner {
  li {
    color: $theme-secondary;
    font-size: 1.1rem;
    &:before {
      color: $theme-primary;
    }
  }
}
#cta-wrap {
  background: #6382a1;
  border-top: 1px solid #013366;
  border-bottom: 1px solid #013366;
  padding: 15px 0;
  text-align: center;
}
#points-wrap {
  background: #f1f1f1;
}
#points {
  h4 {
    margin-top: 0;
    &:before {
      display: inline-block;
      margin: 0 10px;
      width: 40px;
      height: 40px;
      content: '';
      background: transparent url(../images/steps-icon.png) no-repeat 0 0 / cover;
      vertical-align: middle;
      -webkit-transition: opacity 0.25s ease-out 0.25s;
      transition: opacity 0.25s ease-out 0.25s;
      -webkit-transition-delay: 0.1s;
      transition-delay: 0.1s;
      margin-bottom: 7px;
    }
  }
  .steps {
    border: none;
    &:hover {
      img {
        opacity: 0.7;
      }
    }
  }
}
.card {
  background-color: #f7fbfe;
  border-color: #c5e2f0;
}
.alert a {
  color: #df0e09;
  &:hover {
    color: darken(#df0e09, 10%);
    border-bottom: 1px solid darken(#df0e09, 10%);
  }
}
/* --------- asc styles --------- */
.skip-link {
  color: #fff !important;
  background: $theme-secondary !important;
  padding: 10px 20px !important;
  border-bottom: none !important;
  text-decoration: underline !important;
}
#header {
  background: rgba(255, 255, 255, 0.8);
  a.logo {
    border: 0;
    width: 80%;
    max-width: 650px;
    font-family: 'Roboto Slab', 'Open Sans', 'Trebuchet MS', Verdana, Arial, Helvetica, sans-serif;
    img {
      margin-right: 5px;
      width: auto;
      height: auto;
      max-width: 40px;
    }
    span.big,
    span.small {
      font-size: 1.5em;
      line-height: 1em;
    }
    span.big {
      color: $theme-secondary;
    }
    span.small {
      margin-top: 5px;
      font-size: 0.9em;
      line-height: 1.4em;
      color: #555;
    }
  }
  #support {
    display: none;
    float: right;
    margin: 0 0 0 0;
    width: auto;
    a.help-center {
      font-weight: 400;
      font-size: 15px;
      color: #333;
      text-decoration: none;
      border: 0;
      margin-right: 0.25rem;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e830';
        color: inherit;
        font-family: icons;
        font-weight: 400;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        text-decoration: none;
        border-bottom-width: 1px;
        border-bottom-style: dotted;
        border-bottom-color: inherit;
        -webkit-tap-highlight-color: transparent;
      }
    }
    a.login {
      padding: 0 12px 2px 12px;
      color: #fff;
      background: $theme-primary;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;
      display: inline-block;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e828';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        background: lighten($theme-primary, 7.5%);
      }
    }
  }
}
.cta-wrap {
  background: #484f5a;
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header a.logo {
    img {
      max-width: 80px;
      margin-right: 15px;
    }
  }
}
// roughtly 900px/16px
@media only screen and (min-width: 56.25em) {
  #footer p.trustmark {
    display: block;
  }
  #header-wrap {
    border-top: 3px solid $theme-primary;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #header-wrap {
    #header #support {
      display: block;
      span.phone {
        display: inline-block;
      }
    }
  }
  #header a.logo {
    width: 75%;
    span.big {
      font-size: 1.9em;
    }
  }
  .border-lg {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }
  #banner {
    background: #f2f9ff url(../images/banner-bg.jpg) no-repeat 20px 50%;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

// Extra large devices (large desktops, 1400px and up)
@media (min-width: 1400px) {
  .container-fluid.but-fixed {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}
